var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-header border-0 pb-5 align-items-center"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[_c('v-select',{staticClass:"vuetify-custom rounded",style:({
          color: '#1e2758 !important'
        }),attrs:{"id":"daily_monthly_id","items":_vm.data_daily_monthly_combobox,"item-value":"daily_monthly_id","item-text":"daily_monthly_name","solo":""},on:{"change":function($event){return _vm.dataTransfer()}},model:{value:(_vm.data_cashbank.daily_monthly),callback:function ($$v) {_vm.$set(_vm.data_cashbank, "daily_monthly", $$v)},expression:"data_cashbank.daily_monthly"}}),_c('v-menu',{ref:"date_cashbank",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"vuetify-custom px-4 rounded",style:({
              color: '#c30e0e !important'
            }),attrs:{"placeholder":"Pilih Tanggal","solo":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filter_cashbank.cashbank_date),callback:function ($$v) {_vm.$set(_vm.filter_cashbank, "cashbank_date", $$v)},expression:"filter_cashbank.cashbank_date"}},on))]}}]),model:{value:(_vm.date_cashbank),callback:function ($$v) {_vm.date_cashbank=$$v},expression:"date_cashbank"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.date_cashbank = false},"change":function($event){return _vm.onChangeDate(_vm.cashbank_date)}},model:{value:(_vm.cashbank_date),callback:function ($$v) {_vm.cashbank_date=$$v},expression:"cashbank_date"}})],1)],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('v-data-table',{staticClass:"elevation-1 mb-12",attrs:{"headers":_vm.headers,"items":_vm.data_transfer,"item-key":_vm.cashbank_id,"options":_vm.options,"search":_vm.search,"sort-by":"Id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
            var index = ref.index;
return [_c('td',[_vm._v(" "+_vm._s(_vm.options.page * _vm.options.itemsPerPage - _vm.options.itemsPerPage + (index + 1))+" ")])]}},{key:"item.cashbank_desc",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.cashbank_desc === null ? "-" : item.cashbank_desc)+" ")])]}},{key:"item.reference",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.reference === null ? "-" : item.reference)+" ")])]}},{key:"item.amount",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v("Rp "+_vm._s(_vm.formatNumber(item.amount)))])]}}],null,true)})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v("LAPORAN KAS")])])}]

export { render, staticRenderFns }