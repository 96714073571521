<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          TRANSFER TERAKHIR
        </span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0 card-scroll">
      <!--begin::Table-->
      <div class="table-responsive card-body-container">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th class="p-0"></th>
              <th class="p-0"></th>
              <th class="p-0"></th>
              <th class="p-0"></th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr v-for="(item, i) in data_transfer" :key="i">
                <td>
                  <span class="text-td">
                    {{ item.cashbank_date }}
                  </span>
                </td>
                <td>
                  <span class="text-td">
                    {{ item.cashbank_desc }}
                  </span>
                </td>
                <td>
                  <span class="text-td">
                    {{ item.account_number }}
                  </span>
                </td>
                <td style="color: red;">
                  <span class="text-td">
                    Rp{{ formatNumber(item.amount) }}
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<style scoped>
h3 {
  font-size: 18px !important;
}
.card {
  background-color: #e9effd !important;
}

.card-scroll {
  overflow-y: auto;
  max-height: 400px;
}

.card-body-container {
  background-color: #ffffff !important;
  width: auto;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.25rem;
}

.text-right {
  text-align: right;
}

.text-right .text-dark {
  font-size: 18px !important;
}

.text-muted {
  font-size: 10px !important;
}

.text-value {
  font-size: 12px !important;
}

.text-td {
  font-size: 12px !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";

export default {
  name: "last-transfer",

  data: () => ({
    data_transfer: []
  }),

  computed: {
    ...mapGetters(["layoutConfig"])
  },

  methods: {
    loadDate: function() {
      this.date_published = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_published = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateEnd: function() {
      this.date_end = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_end = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_start.save(item);
      this.data_bank_guarantee_date.date_published = item;
    },

    onChangeDateEnd(item) {
      this.$refs.est_date_end.save(item);
      this.data_bank_guarantee_date.date_end = item;
    },

    dataTransfer() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.UID,
          Route: "LAST_TRANSFER"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_transfer = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([await setTimeout(() => this.dataTransfer(), 600)]).then(
        function(results) {
          results;
        }
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_bank_guarantee.guarantee_id = "";
        this.add_bank_guarantee.project_id = "";
        this.add_bank_guarantee.guarantee_address = "";
        this.add_bank_guarantee.account_number = "";
        this.add_bank_guarantee.total_amount = "";
        this.add_bank_guarantee.total_paid = "";
        this.add_bank_guarantee.total_received = "";
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    formatNumber
  },

  mounted() {
    this.load();

    this.user_role = localStorage.User_Role;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  }
};
</script>
