<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pb-5 align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">LAPORAN KAS</span>
      </h3>
      <div class="card-toolbar">
        <v-select
          v-model="data_cashbank.daily_monthly"
          id="daily_monthly_id"
          :items="data_daily_monthly_combobox"
          item-value="daily_monthly_id"
          item-text="daily_monthly_name"
          class="vuetify-custom rounded"
          solo
          v-bind:style="{
            color: '#1e2758 !important'
          }"
          @change="dataTransfer()"
        ></v-select>
        <v-menu
          ref="date_cashbank"
          v-model="date_cashbank"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          full-width
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filter_cashbank.cashbank_date"
              placeholder="Pilih Tanggal"
              class="vuetify-custom px-4 rounded"
              solo
              v-bind:style="{
                color: '#c30e0e !important'
              }"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="cashbank_date"
            no-title
            @input="date_cashbank = false"
            @change="onChangeDate(cashbank_date)"
          ></v-date-picker>
        </v-menu>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div class="form-group">
        <v-data-table
          :headers="headers"
          :items="data_transfer"
          :item-key="cashbank_id"
          :options.sync="options"
          :search="search"
          sort-by="Id"
          class="elevation-1 mb-12"
        >
          <template v-slot:[`item.number`]="{ index }">
            <td>
              {{
                options.page * options.itemsPerPage -
                  options.itemsPerPage +
                  (index + 1)
              }}
            </td>
          </template>
          <template v-slot:[`item.cashbank_desc`]="{ item }">
            <td>
              {{ item.cashbank_desc === null ? "-" : item.cashbank_desc }}
            </td>
          </template>
          <template v-slot:[`item.reference`]="{ item }">
            <td>
              {{ item.reference === null ? "-" : item.reference }}
            </td>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <td>Rp {{ formatNumber(item.amount) }}</td>
          </template>
        </v-data-table>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style scoped>
h3 {
  font-size: 18px !important;
}

.card,
.card-header {
  background-color: #f8f9fa !important;
}

.card-scroll {
  overflow-y: auto;
  max-height: 400px;
}

.card-body-container {
  background-color: #ffffff !important;
  width: auto;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.25rem;
}

.card-date-filter {
  background-color: #ffffff !important;
}

.card-daily-filter {
  background-color: #ffffff !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.text-right {
  text-align: right;
}

.text-right .text-dark {
  font-size: 18px !important;
}

.text-muted {
  font-size: 10px !important;
}

.text-value {
  font-size: 12px !important;
}

.text-td {
  font-size: 12px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";
import { formatDate } from "@/helpers/helper.js";

export default {
  name: "card-cashbank",

  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    dialogDeleteDetail: false,
    data_transfer: [],
    data_bank_account_combobox: [],
    data_bank_guarantee_combobox: [],
    data_project_combobox: [],
    data_coa_combobox: [],
    filter_cashbank: {
      date_cashbank: false,
      cashbank_date: formatDate(),
      date: formatDate()
    },
    data_cashbank_date_filter: {
      cashbank_date: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10)
    },
    data_daily_monthly_combobox: [
      {
        daily_monthly_id: "D",
        daily_monthly_name: "HARIAN"
      },
      {
        daily_monthly_id: "M",
        daily_monthly_name: "BULANAN"
      }
    ],
    data_cashbank: {
      daily_monthly: "D"
    },
    add_transfer_detail: {
      guarantee_id: "",
      project_id: "",
      coa_code: "",
      destination_account_number: 0,
      amount: 0
    },
    add_transfer_not_null: {
      date_start: false,
      est_date_end: false,
      date_published: formatDate(),
      date_end: formatDate()
    },
    submitted: false,
    headers: [],
    headers_transfer_detail: [],
    editedIndex: -1,
    deletedIndex: -1,
    editedItem: {},
    editedItemDetail: {},
    deletedItem: {},
    deletedItemDetail: {},
    defaultItem: {},
    isFirstLoad: true,
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false
    },
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    option: {
      locale: "en-US",
      prefix: "",
      suffix: "",
      length: 20,
      precision: 0
    },
    cashbank_date: "",
    date: "",
    user_role: "",
    seen: false
  }),

  computed: {
    formInputNotClear() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_transfer_not_null;
      } else if (this.editedIndex === -2) {
        result = this.editedItemDetail;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    computedDate() {
      return this.formatDate(this.cashbank_date);
    },

    computedDateModified() {
      return this.formatDate(this.date);
    },

    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    dialogDeleteDetail(val) {
      val || this.closeDeleteDetail();
    },

    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataDoMasuk();
        this.loading = false;
      },
      deep: true
    },

    cashbank_date() {
      this.filter_cashbank.cashbank_date = this.formatDate(this.cashbank_date);
    },

    date() {
      this.filter_cashbank.date = this.formatDate(this.date);
    }
  },

  methods: {
    getHeaders() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "cashbank_id",
          align: " d-none"
        },
        { text: "NO", value: "number" },
        {
          text: "TGL TRANSFER",
          sortable: true,
          value: "cashbank_date"
        },
        { text: "DESKRIPSI", sortable: true, value: "cashbank_desc" },
        {
          text: "REFERENSI",
          sortable: true,
          value: "reference"
        },
        {
          text: "NOMINAL",
          sortable: true,
          value: "amount"
        },
        {
          text: "KETERANGAN",
          sortable: true,
          value: "cashbank_type"
        }
      ];
    },

    loadDate: function() {
      this.cashbank_date = new Date().toISOString().substr(0, 10);
      this.filter_cashbank.cashbank_date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.filter_cashbank.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_cashbank.save(item);
      this.data_cashbank_date_filter.cashbank_date = item;
      this.dataTransfer();
    },

    onChangeDailyMonthly() {
      this.dataTransfer();
    },

    dataTransfer() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.UID,
          Route: "CASHBANK_DEFAULT_DETAIL",
          cashbank_date: this.data_cashbank_date_filter.cashbank_date,
          daily_monthly: this.data_cashbank.daily_monthly
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/Cashbank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_transfer = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([await setTimeout(() => this.dataTransfer(), 800)]).then(
        function(results) {
          results;
        }
      );
    },

    editItem(item) {
      this.editedIndex = this.data_transfer.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedIndex = this.data_transfer.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemDetail(item) {
      this.deletedIndex = this.data_transfer_detail.indexOf(item);
      this.deletedItemDetail = Object.assign({}, item);
      this.dialogDeleteDetail = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // this.add_transfer.period_code = "";
        this.add_transfer.cashbank_desc = "";
        this.add_transfer.reference = "";
        this.add_transfer.account_number = "";
        this.add_transfer_detail.guarantee_id = "";
        this.add_transfer_detail.project_id = "";
        this.add_transfer_detail.coa_code = "";
        this.add_transfer_detail.destination_account_number = "";
        this.add_transfer_detail.amount = 0;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItemDetail = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    save(formInput) {
      if (formInput == "add_transfer") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_transfer[this.editedIndex],
            this.add_transfer
          );
        } else {
          this.data_transfer.push(this.add_transfer);
        }
      } else if (formInput == "edit_transfer") {
        if (this.editedIndex > -1) {
          Object.assign(this.data_transfer[this.editedIndex], this.editedItem);
        } else {
          this.data_transfer.push(this.editedItem);
        }
      }
      this.close();
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    formatNumber
  },

  created() {
    this.initHeders();
  },

  mounted() {
    this.load();

    this.user_role = localStorage.User_Role;
  }
};
</script>
