<template>
  <!--begin::List Widget 4-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">BANK GARANSI</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 card-scroll">
      <template>
        <!--begin::Item-->
        <div
          class="d-flex align-items-center mb-2 card-body-container"
          v-for="(item, i) in data_bank_guarantee"
          :key="i"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex flex-row flex-grow-1">
              <div class="d-flex flex-column flex-grow-1 font-weight-medium">
                <span class="text-muted">
                  NAMA PROYEK
                </span>
                <span class="text-dark mb-1 text-value">
                  {{ item.project_name }}
                </span>
              </div>
              <div class="d-flex flex-column font-weight-medium text-right">
                <span class="text-muted">
                  NILAI BANK GARANSI
                </span>
                <span class="text-dark mb-1">
                  Rp{{ formatNumber(item.total_amount) }}
                </span>
              </div>
            </div>
            <hr />
            <div class="d-flex flex-row flex-grow-1">
              <div class="d-flex flex-column flex-grow-1 font-weight-medium">
                <span class="text-muted">
                  TANGGAL BERLAKU S/D
                </span>
                <span class="text-dark mb-1 text-value">
                  {{ item.date_end }}
                </span>
              </div>
              <div class="d-flex flex-column font-weight-medium text-right">
                <span class="text-muted">
                  NILAI TRANSFER
                </span>
                <span class="text-dark mb-1">
                  Rp{{ formatNumber(item.total_paid) }}
                </span>
              </div>
            </div>
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 4-->
</template>

<style scoped>
h3 {
  font-size: 18px !important;
}

.card {
  background-color: #e9effd !important;
}
.card-scroll {
  overflow-y: auto;
  max-height: 400px;
}

.card-body-container {
  background-color: #ffffff !important;
  width: auto;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.25rem;
}

.text-right {
  text-align: right;
}

.text-right .text-dark {
  font-size: 18px !important;
}

.text-muted {
  font-size: 10px !important;
}

.text-value {
  font-size: 12px !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";

export default {
  name: "card-bank-guarantee",

  data: () => ({
    data_bank_guarantee: []
  }),

  computed: {
    ...mapGetters(["layoutConfig"])
  },

  methods: {
    getHeaders() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "guarantee_id",
          align: " d-none"
        },
        { text: "No", value: "number", width: "70px" },
        {
          text: "Kode Bank Garansi",
          sortable: true,
          value: "guarantee_id",
          width: "180px"
        },
        {
          text: "NAMA PROYEK",
          sortable: true,
          value: "project_name",
          width: "150px"
        },
        {
          text: "No Rekening",
          sortable: true,
          value: "account_number",
          width: "140px"
        },
        {
          text: "NILAI BANK GARANSI",
          sortable: true,
          value: "total_amount",
          width: "180px"
        },
        {
          text: "NILAI TRANSFER",
          sortable: true,
          value: "total_paid",
          width: "150px"
        },
        {
          text: "Tgl berlaku s/d",
          sortable: true,
          value: "date_end",
          width: "160px"
        },
        {
          text: "Nilai Pencairan",
          sortable: true,
          value: "total_received",
          width: "160px"
        },
        {
          text: "Alamat Bank Garansi",
          sortable: true,
          value: "guarantee_address",
          width: "200px"
        },
        {
          text: "Edit",
          value: "aksi",
          sortable: false,
          width: "100px"
        }
      ];
    },

    loadDate: function() {
      this.date_published = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_published = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateEnd: function() {
      this.date_end = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_end = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_start.save(item);
      this.data_bank_guarantee_date.date_published = item;
    },

    onChangeDateEnd(item) {
      this.$refs.est_date_end.save(item);
      this.data_bank_guarantee_date.date_end = item;
    },

    dataBankGuarantee() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.UID,
          Route: "DEFAULT"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([await setTimeout(() => this.dataBankGuarantee(), 400)]).then(
        function(results) {
          results;
        }
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_bank_guarantee.guarantee_id = "";
        this.add_bank_guarantee.project_id = "";
        this.add_bank_guarantee.guarantee_address = "";
        this.add_bank_guarantee.account_number = "";
        this.add_bank_guarantee.total_amount = "";
        this.add_bank_guarantee.total_paid = "";
        this.add_bank_guarantee.total_received = "";
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    formatNumber
  },

  mounted() {
    this.load();

    this.user_role = localStorage.User_Role;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  }
};
</script>
